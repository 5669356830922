/* react transition group transitions */

//fade transition
.fade {
    &-enter {
        opacity: 0;

        &-active {
            opacity: 1;
            transition: 250ms ease-out;
        }
    }

    &-exit {
        opacity: 1;

        &-active {
            opacity: 0;
            transition: 250ms ease-out;
        }
    }
}
